<template>
  <div class="modal fade" tabindex="-1">
    <div
      class="modal-dialog modal-dialog-centered mw-950px modal-fullscreen-lg-down"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("CondicionesPrivacidadModalTitulo") }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div
            class="mb-5 textoJustificado"
            v-html="$t('CondicionesPrivacidadModalBody')"
          ></div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            {{ $t("CondicionesPrivacidadModalButton") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
