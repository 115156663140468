<template>
  <!--begin::Card Body-->
  <div class="card-body p-10">
    <div class="mb-5 fs-1">{{ $t("contacto") }}</div>
    <div class="row">
      <div class="col-sm-12 col-lg-4 my-2">
        <div class="fs-3 fw-bold">{{ $t("zaragoza") }}</div>
        <div class="my-3">
          <BIconGeoAlt style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("direccionZaragoza") }}</span>
          <br /><span class="ms-9">{{ $t("direccionZaragoza2") }}</span>
        </div>
        <div class="my-3">
          <BIconTelephone style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("telefonoZaragoza") }}</span>
        </div>
        <div class="my-3">
          <BIconEnvelope style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("emailZaragoza") }}</span>
        </div>
      </div>

      <div class="col-sm-12 col-lg-4 my-2">
        <div class="fs-3 fw-bold">{{ $t("huesca") }}</div>
        <div class="my-3">
          <BIconGeoAlt style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("direccionHuesca") }}</span>
          <br /><span class="ms-9">{{ $t("direccionHuesca2") }}</span>
        </div>
        <div class="my-3">
          <BIconTelephone style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("telefonoHuesca") }}</span>
        </div>
        <div class="my-3">
          <BIconEnvelope style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("emailHuesca") }}</span>
        </div>
      </div>

      <div class="col-sm-12 col-lg-4 my-2">
        <div class="fs-3 fw-bold">{{ $t("teruel") }}</div>
        <div class="my-3">
          <BIconGeoAlt style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("direccionTeruel") }}</span>
          <br /><span class="ms-9">{{ $t("direccionTeruel2") }}</span>
        </div>
        <div class="my-3">
          <BIconTelephone style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("telefonoTeruel") }}</span>
        </div>
        <div class="my-3">
          <BIconEnvelope style="font-size: 1.5rem" />
          <span class="ms-3 fs-5">{{ $t("emailTeruel") }}</span>
        </div>
      </div>
    </div>
  </div>
  <!--begin::Card Body-->
</template>
